import { signOut } from "next-auth/react";
import { toast } from "react-toastify";

export const getApiError = async (error: any) => {
    // const errorObject = error instanceof TypeError ? error?.message :JSON.parse(error.message);
    if (typeof error === 'string') {
        const errorObject = JSON.parse(error);
        switch (errorObject?.status) {
            case 401:
                toastError(errorObject?.message);
                break;

                // return await signOut({ callbackUrl: '/auth/login' });
            default:
                return toastError(errorObject?.message);
        }
    }
}

const toastError = (message: string) => {
    toast.error(message, {
        position: "top-right",
    });
}